<template>
  <v-container>
    <v-row style="height:15vh;">
    </v-row>
    <v-row justify="center" style="height:10vh;" class="text-center">
        <span  :class = "`${$vuetify.breakpoint.mobile? 'text-h4':'text-h2'} mb-7`">Find Your Charity</span>
    </v-row>
    <v-expand-transition>
      <div v-if="!byLocation">
        <v-row  justify = "center" no-gutters>
          <v-col cols="11">
            <SearchBar :category="chosenCategory" ></SearchBar>
          </v-col>
        </v-row>
        <v-row justify="center" class="mt-n7">
          <v-col :cols="$vuetify.breakpoint.mobile?'8' :'3'" class="text-center">
            <ChipBox label ="Charity Categories" hint="Specify 1 keyword for category search"
              :multiple="false" isApplicable="true"
              :choices.sync="chosenCategory" >
            </ChipBox>
          </v-col>
        </v-row>
      <v-divider class="mb-7"></v-divider>
      </div>
    </v-expand-transition>
    <v-card>
      <v-card-actions>
      <v-row justify="center">
        <v-btn @click = "expandLocationSearch" :loading="locationLoading" fab elevation="15" >
          <v-icon large color="primary" v-if="!byLocation">mdi-crosshairs-gps</v-icon>
          <v-icon large color="primary" v-else >mdi-close-circle-outline</v-icon>
        </v-btn>
      </v-row>
    </v-card-actions>
    <LocationSearchCard :expand.sync="byLocation"
    :searchLoading.sync="locationLoading"></LocationSearchCard>
    </v-card>
  </v-container>
</template>

<script>
import SearchBar from '@/components/search/SearchBar.vue'
import LocationSearchCard from '@/components/search/LocationSearchCard.vue'
import ChipBox from '@/components/fields/ChipBox.vue'
export default {
  components: {
    SearchBar, LocationSearchCard, ChipBox
  },
  data () {
    return {
      chosenCategory: '',
      byLocation: false,
      locationLoading: false
    }
  },
  methods: {
    expandLocationSearch () {
      this.byLocation = !this.byLocation
    }
  }
}
</script>
