<template>
  <v-combobox
    v-if="isApplicable"
    v-model="userchoices"
    :search-input.sync="input"
    :items="offeredOptions"
    :label="label"
    :hint="hint"
    deletable-chips
    :multiple="multipleChips" clearable solo
    :rules="numberValidator"
    background-color="transparent"
    >
    <template v-slot:selection="userchoice">
      <v-chip label close :color="colorchoices[userchoice.index]"
          @click:close="userchoices.splice(userchoices.indexOf(userchoice.item),1)"
          v-bind="userchoice.attrs">{{userchoice.item}}</v-chip>
    </template>

    <template v-slot:no-data>
      <v-row class = "ml-7" >
        Click <kbd class= "mx-2"> ENTER </kbd> to add
          <v-chip small label class="mx-2" v-if="input!==null">
            {{input}}</v-chip>
      </v-row>
    </template>
  </v-combobox>
</template>
<script>
export default {
  name: 'ChipBox',
  props: ['label', 'choices', 'hint', 'offeredOptions', 'isApplicable', 'multiple'],
  data () {
    return {
      userchoices: this.choices,
      input: '',
      colors: ['pink lighten-1', 'teal darken-1', 'indigo lighten-1', 'deep-purple lighten-1',
        'light-green darken-1'],
      colorchoices: ['pink darken-1'],
      colorcounter: 0,
      numberValidator: [
        (input) => {
          /**
           * depends on the parent component to determine if this input is a applicable and has multiple chips.
           * if the use is a charity, this must be at most 5 and at least 1
           */
          if (this.isApplicable && this.multipleChips) {
            return (input.length <= 5 && input.length > 0) || 'Please give 1-5 categories to identify yourself.'
          } else {
            return true
          }
        }
      ]
    }
  },
  computed: {
    // by default multiple chip inputs are allowed
    multipleChips () {
      if (this.multiple !== undefined) {
        return this.multiple
      } else {
        return true
      }
    }
  },
  watch: {
    userchoices: {
      deep: true,
      handler (newValue) {
        this.colorcounter = parseInt(this.colorcounter + 1) % 3
        this.colorchoices.push(this.colors[this.colorcounter])
        this.$emit('update:choices', newValue)
      }

    }
  },
  created () {
    if (this.isApplicable) {
      this.colorchoices = this.colors.splice(0,
        this.choices.length)
    }
  }
}
</script>
