<template>
<div>
  <!--reference: https://vuetifyjs.com/en/api/v-autocomplete/#props
  - searchResult is the array to be searched
  - search-input is what user has typed and what has been picked by user will be reflected by to the input field
  - item-text is the property of each object in searchResult, taht is used for searching.
  - v-model contains the data that reflects what user has selected
  - return-object is to return user selection in object form
  - the others have the simple meaning for improving user expereinces
  -->
  <v-autocomplete
    :search-input.sync="searchInput" :items="searchResult"
    item-text="searchContent" item-value="email" type='email'
    v-model="userSelected"
    return-object auto-select-first
    clearable solo hide-no-data cache-items
    prepend-inner-icon="mdi-magnify"
    label="Search Charity Name"
    hint="Type Your Charity Name"
    :loading="loading"
    class="mx-auto"
  >
    <!--slot for customising the list of search result -->
    <template v-slot:item="users">
      <v-list-item-avatar :color="$vuetify.theme.themes.light.charityAvatar">
        <v-img v-if="users.item.picture" :src="users.item.picture"></v-img>
        <h1 v-else class="white--text">{{users.item.shortName}}</h1>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>{{users.item.name}}</v-list-item-title>
        <v-list-item-subtitle>{{users.item.email}}</v-list-item-subtitle>
        <v-list-item-content>
          <v-chip-group column class="mt-n3">
              <v-chip x-small label v-for="(category,n) in users.item.categories"
              :key="n" :color="colors[n]">
                {{category}}</v-chip>
          </v-chip-group>
        </v-list-item-content>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Vue from 'vue'
// import store from '@/store'
export default {
  props: ['category'],
  data () {
    return {
      userSelected: null,
      searchInput: null,
      colors: ['pink lighten-1', 'teal darken-1', 'indigo lighten-1', 'deep-purple lighten-1',
        'light-green darken-1'],
      loading: false
    }
  },
  computed: {
    ...mapGetters('charityStore', ['charities', 'lastSearch']),
    /**
     * return and process loaded charities from the store and users input will search these based on searchContent
     * name and email are combined using '?' to separate them so that users can search email or name
     */
    searchResult () {
      return this.lastSearch.map((charity) => {
        charity.searchContent = `${charity.name}?${charity.email}`
        charity.shortName = charity.name.substring(0, 2)
        return charity
      })
    }
  },
  watch: {
    /**
     * Update when search list in the charitystore when the user inputs something
     * the results retrieved from the backend account service are managed centrally by the Vuex
     *  and will be retrieved from searchResult() function at 'computed'
     * Avoid search when '?' is included. '?' is used to separete the combination of email and name in search results.
     */
    searchInput (newValue) {
      // console.log('search', this.loading)
      // console.log(newValue)
      if (newValue && !newValue.includes('?')) {
        const input = {
          content: newValue,
          category: this.category
        }
        this.loading = true
        this.searchCharities(input)
          .finally(() => {
            this.loading = false
          })
      }
    },
    /* trigger when user picks one of the selection from the list */
    userSelected (newValue) {
      const firstSegment = Vue.$pathConverter.getFirstSegment()
      // check if the url belongs to a charity user or the system (/iocharity)
      if (firstSegment.includes('@')) {
        this.$router.push({
          name: 'CharityView',
          params: {
            root: Vue.$pathConverter.getFirstSegment(),
            charity: newValue.email
          }
        })
      } else {
        this.$router.push({ name: 'Home', params: { root: newValue.email } })
      }
    }
  },
  methods: {
    ...mapActions('charityStore', [
      'searchCharities'
    ])
  }
}
</script>
