<template>
  <v-card
     class="mx-auto" elevation="0" color="transparent"
    :width="$vuetify.breakpoint.mobile ? '80%' : '38%'"
  >
    <v-card-title>
      <v-row justify="center" class="text-h6 text-center">
        Find Charities Near to Me <br>
      </v-row>
    </v-card-title>
    <div v-if="this.expand">
    <v-card v-if="!this.error && charities.length>0" elevation="0" color="transparent">
      <v-card-text>
        <v-list elevation="2">
          <v-list-item v-for="charity in charities" :key="charity.email" class="text-wrap">
            <v-list-item-avatar :color="$vuetify.theme.themes.light.charityAvatar">
              <v-img v-if="charity.picture" :src="charity.picture"></v-img>
              <h1 v-else class="white--text">{{charity.name.substring(0,2)}}</h1>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{charity.name}}</v-list-item-title>
              <v-list-item-subtitle>{{charity.email}}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn @click = "navigate(charity)" fab elevation="7">
                <v-icon color="primary">mdi-arrow-right-bold-hexagon-outline</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
      </v-list>
      </v-card-text>
    </v-card>
    <v-card v-else-if="!this.error && charities.length <= 0"
        elevation="0" color="transparent" class="my-10">
      <v-card-text>
      <v-row justify="center"
        class="text-center info--text text-h5 text-wrap">
        <p v-if="searchLoading">Loading</p><p v-else>No charity is found</p>
      </v-row>
      </v-card-text>
    </v-card>
    <v-card v-else  elevation="0" color="transparent">
      <v-row justify="center" v-if="error"
        class=" my-3 text-center error--text text-subtitle-2 text-wrap">
        {{errorMessage}}
      </v-row>
    </v-card>
    </div>
  </v-card>
</template>

<script>
import Vue from 'vue'
export default {
  name: 'LocationSearchCard',
  props: ['expand', 'searchLoading'],
  data () {
    return {
      error: false,
      errorMessage: '',
      charities: []
    }
  },
  methods: {
    getGeolocation () {
      if ('geolocation' in navigator) {
        this.$emit('update:searchLoading', true)
        navigator.geolocation.getCurrentPosition(
          (coordination) => {
            this.error = false
            // console.log(coordination)
            // be empty string to search all
            const searchcontent = ''
            Vue.$publicApi('/account/searchcharity/geolocation',
              {
                params: {
                  content: searchcontent,
                  lat: coordination.coords.latitude,
                  lng: coordination.coords.longitude
                }
              }).then((response) => {
              // console.log(response.data)
              this.error = false
              const data = response.data
              this.charities = data
            }).catch((e) => {
              console.error(`Fail to retrieve ${e}`)
              this.error = true
              this.errorMessage = 'Fail to access GeoService. Please try later.'
            }).finally(() => {
              this.$emit('update:searchLoading', false)
            })
          },
          (e) => {
            // console.log('failt to retrieve location')
            this.error = true
            this.errorMessage = 'Fail to query your machine. PERMISSION REQUIRED. Please Turn on through your Browser'
          }
        )
      } else {
        this.error = true
        this.errorMessage = 'Opps! seems like your Web Browser (where you install your PWA) does not support this'
      }
    },
    navigate (charity) {
      const firstSegment = Vue.$pathConverter.getFirstSegment()
      // check if the url belongs to a charity user or the system (/iocharity)
      if (firstSegment.includes('@')) {
        this.$router.push({
          name: 'CharityView',
          params: {
            root: Vue.$pathConverter.getFirstSegment(),
            charity: charity.email
          }
        })
      } else {
        this.$router.push({ name: 'Home', params: { root: charity.email } })
      }
    }
  },
  watch: {
    expand (newValue) {
      // console.log('watch', newValue)
      if (newValue) {
        this.getGeolocation()
      }
    }
  }
}
</script>
